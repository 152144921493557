import { UserSettings } from './user-settings';

export class UserProfile {

   public role!: string;
   public userSettings!: UserSettings;

   // Database properties (on-premise)
   public mail?: string;
   public alternativeMail?: string;
}
