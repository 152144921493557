import { Injectable } from '@angular/core';
import { FunctionClient } from '../azure-functions';
import { environment } from 'src/environments/environment';
import { ClientConfiguration } from '../model/client-configuraton';
import { EProviderType } from '../authentication/model/eprovider-type';
import { TelemetryService } from './telemetry.service';
import { UserProfile } from '../model/user-profile';


@Injectable()
export class CoreService {

   public isBusy = false;
   private controllerClientConfiguration: FunctionClient;
   private controllerSendTestMail?: FunctionClient;
   private controllerUserProfile?: FunctionClient;
   private clientConfigurationValue?: ClientConfiguration;

   constructor(
      private telemetryService: TelemetryService
   ) {
      this.controllerClientConfiguration = new FunctionClient(telemetryService, environment.clientEndpoints.Core_ClientConfiguration);
   }


   private initControllers() {
      this.controllerSendTestMail = new FunctionClient(this.telemetryService, this.clientConfiguration.apiEndpoints.Core_SendTestMail);
      this.controllerUserProfile = new FunctionClient(this.telemetryService, this.clientConfiguration.apiEndpoints.Core_UserProfile);

   }

   public get clientConfiguration(): ClientConfiguration {
      return this.clientConfigurationValue!;
   }

   public async loadClientConfigurationAsync(): Promise<void> {
      // Load server-specific configuration
      const url = `${this.controllerClientConfiguration.url}?client=web`;

      const config = await this.controllerClientConfiguration.callFunctionGetAsync<ClientConfiguration>(url);
      let configuration = new ClientConfiguration();

      // Add default configuration
      configuration = this.deepMerge(configuration, environment);

      // Load server-specific configuration
      configuration = this.deepMerge(configuration, config);

      // Fix enum types
      if (typeof configuration.authentication.provider === 'string') {
         configuration.authentication.provider = (EProviderType[configuration.authentication.provider as any] as any);
      }

      // Override redirect for localhost (for debugging)
      if (window.location.origin.indexOf('localhost') > -1) {
         configuration.authentication.redirectUri = window.location.origin;
         configuration.authentication.postLogoutRedirectUri = window.location.origin;
      }

      this.clientConfigurationValue = configuration;
      console.info('[Core]', 'Client configuration loaded.');

      this.initControllers();
   }

   public async loadUserProfileAsync(): Promise<UserProfile> {
      try {
         this.isBusy = true;

         if (this.controllerUserProfile) {
            const url = this.controllerUserProfile.url;
            return await this.controllerUserProfile.callFunctionGetAsync<UserProfile>(url);
         }
         else {
            return new UserProfile();
         }
      }
      catch {
         return new UserProfile();
      }
      finally {
         this.isBusy = false;
      }
   }

   public async sendTestMail(mail: string): Promise<void> {
      try {
         this.isBusy = true;

         if (this.controllerSendTestMail) {
            const url = `${this.controllerSendTestMail.url}&mail=${mail}`;
            await this.controllerSendTestMail.callFunctionGetAsync<void>(url);
         }
      }
      finally {
         this.isBusy = false;
      }
   }

   /**
    * Deep-merges a source object into a target object.
    */
   private deepMerge<T>(target: T, source: any): T {
      const sourceKeys = Object.keys(source);
      if (sourceKeys.length === 0) { return target; }

      sourceKeys.forEach(key => {
         const sourceValue = source[key];
         if (Array.isArray(sourceValue)) {
            // Value is of type array
            (<any>target)[key] = source[key];
         }
         if (sourceValue !== null && typeof sourceValue === 'object') {
            // Value is of type object
            if ((<any>target)[key] === undefined) { (<any>target)[key] = {}; }
            const targetValue = this.deepMerge((<any>target)[key], source[key]);
            (<any>target)[key] = targetValue;
         }
         else {
            // Value is something else
            (<any>target)[key] = source[key];
         }
      });

      return target;
   }
}
